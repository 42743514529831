/* eslint-disable react/no-danger */
import React, { useEffect, useReducer } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useDisconnect, useSignMessage, useConnect, useAccount } from "wagmi";
import { Button, Image, SVG } from "~components";

import { walletAddressAreEqual, TARGET_CHAIN } from "~utils/helpers";
import { useApp, useKeyPress } from "~hooks";
import walletconnect from "~assets/images/walletconnect.png";
import { breakpoint } from "~utils/css";
import loader from "~assets/images/loader.webp";
import metamask from "~assets/images/metamask.png";
import google from "~assets/images/google.svg";
import discord from "~assets/images/discord.svg";
import x from "~assets/images/x.svg";
import apple from "~assets/images/apple.svg";
import altrLoginAPI from "~hooks/altrLoginApi";
import SIWEService from "~dApp/SIWEService";
import LoginService from "~services/auth";
import TermsAndConditionsService from "~services/termsAndConditions";
import ConnectOverlayWalletButton from "./ConnectOverlayWalletButton";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  transition:
    opacity 0.3s var(--cubic-easing),
    transform 0.3s var(--cubic-easing);

  opacity: ${({ active }) => `${active ? 1 : 0}`};

  ${({ active }) => {
    if (active) {
      return css`
        pointer-events: auto;
      `;
    }
    return css`
      pointer-events: none;
      * {
        pointer-events: none !important;
      }
    `;
  }};

  transform: scale(${({ active }) => `${active ? 1 : 1.05}`});

  width: 100vw;
  height: 100svh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 90;
  display: flex;
  align-items: center;
  justify-content: center;

  .connectOverlay {
    &Subheading {
      color: #919191;
      margin-bottom: 5px;
    }

    &AcceptButton {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      transition-property: none;
      ${({ isLoading }) =>
        `${isLoading ? `background-image: url(${loader}); background-color: transparent; color: transparent; border: 1px solid black;` : ``}`};
      &:hover {
        ${({ isLoading }) => `${isLoading ? `background-color: transparent; color: transparent; border: 1px solid black;` : ``}`};
      }
      &Text {
        ${({ isLoading }) => `${isLoading ? `color: transparent;` : ``}`};
      }
    }
  }
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.9;
  background: black;
`;

const LoadingAnimation = styled.div`
  transition: opacity 0.3s var(--cubic-easing);

  opacity: ${({ active }) => `${active ? 1 : 0}`};
  pointer-events: ${({ active }) => `${active ? `auto` : `none`}`};

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
`;

//
// Connectors

const Connectors = styled.article`
  width: calc(100% - 2rem);
  position: relative;
  margin: 0 auto;
  z-index: 10;
  padding: 20px 24px 40px;
  color: var(--color-white);
  box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.35);
  border-radius: 16px;
  overflow-y: ${({ prosAndCons }) => (prosAndCons ? `scroll` : `hidden`)};
  background: #161616;
  max-height: 90vh;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${breakpoint(`large-tablet`)} {
    width: 426px;
  }
`;
const CloseButton = styled.button`
  position: relative;
  z-index: 30;
`;
const CloseButtonInner = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: block;
  z-index: 30;
`;
const ConnectorsHeader = styled.header`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${({ position }) => (position === `left` ? `flex-start` : `flex-end`)};
`;
const ConnectorsHeading = styled.h2`
  margin-bottom: 8px;
`;
const ConnectorList = styled.ul`
  width: 100%;
  position: relative;
  margin-top: 1.5rem;
`;
const SocialLoginConnectors = styled.li`
  display: grid;
  grid-gap: 15px;

  .socialLoginButton {
    --background-color: #282828;
    --border-color: #282828;
    --hover-background-color: #535353;
    --hover-border-color: #535353;
    --text-color: white;

    border-radius: 1rem;
    padding: 1rem 0;

    &Text {
      margin-left: 11px;
    }
  }

  p {
    grid-column: 1 / 4;
  }
`;
const ProsAndConsButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  span {
    text-transform: none;
  }
`;
//
// Terms

const Terms = styled.div`
  width: calc(100% - 2rem);
  max-height: calc(100vh - 6rem);
  position: relative;
  margin: 0 auto;
  z-index: 10;
  background: var(--color-mono-10);
  padding: 20px 24px 40px;
  box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.35);
  border-radius: 16px;

  ${breakpoint(`large-tablet`)} {
    width: 878px;
    max-height: calc(100vh - 12rem);
  }
`;
const TermsText = styled.div`
  width: 100%;
  height: calc(70vh - 12rem);
  // max-height: 492px;
  position: relative;
  z-index: 10;
  overflow-y: scroll;
  margin-top: 1rem;
  padding: 30px;
  border-radius: 8px;
  background: var(--color-mono-20);
`;

//
// Pros and Cons
const ProsAndCons = styled.div`
  padding: 0 12px;
  color: white;
`;
const ProsAndConsHeading = styled.h2`
  font-size: 24px;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.24px;
  margin: 20px 0;
`;
const ProsAndConsList = styled.ul`
  list-style: disc;
`;
const ProsAndConsListElement = styled.li`
  font-size: 16px;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.16px;
  margin-left: 20px;
`;

const PANES = Object.freeze({
  CONNECT: 0,
  PROS_AND_CONS: 1,
  TERMS: 2
});

const ACTIONS = Object.freeze({
  SET_LOADING: 0,
  SET_ERROR: 1,
  SET_NONCE: 2,
  SET_TERMS: 3,
  SET_SIGNED_MESSAGE: 4,
  SET_PANE: 5,
  SET_CAN_SIGN: 6,
  RESET: 7
});

const initialState = {
  isLoading: false,
  error: null,
  pane: PANES.CONNECT,
  nonce: ``,
  terms: undefined
};

const authInProgressKey = `authInProgress`;

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_LOADING:
      return { ...state, isLoading: action.payload };
    case ACTIONS.SET_ERROR:
      localStorage.removeItem(authInProgressKey);
      return { ...initialState, error: action.payload };
    case ACTIONS.SET_NONCE:
      return { ...initialState, nonce: action.payload };
    case ACTIONS.SET_TERMS:
      return { ...initialState, terms: action.payload };
    case ACTIONS.SET_PANE:
      return { ...state, pane: action.payload };
    case ACTIONS.RESET:
      localStorage.removeItem(authInProgressKey);
      return initialState;
    default:
      return state;
  }
}

/** ============================================================================
 * @component
 */
const ConnectOverlay = () => {
  // ---------------------------------------------------------------------------
  // wagmi

  const { connectAsync, connectors } = useConnect();
  const { disconnect: walletDisconnect } = useDisconnect();
  const { connector } = useAccount();
  const { signMessageAsync } = useSignMessage();
  // ---------------------------------------------------------------------------
  // context
  const { connectorActive, setConnectorActive, setUserIsLoggedIn, setUserData, userData, setReloadProfileData, reloadProfileData } = useApp();

  // services
  const loginService = new LoginService();
  const siweService = new SIWEService();
  const termsAndConditionsService = new TermsAndConditionsService();

  // logins
  const { getNonceForOnboardedUser, walletAuthentication, useIsAValidLoginToken, updateUserSession } = altrLoginAPI();

  // general
  const escKeyPressed = useKeyPress(`Escape`);
  useIsAValidLoginToken(userData?.user, setUserIsLoggedIn);

  // state
  const [state, dispatch] = useReducer(reducer, initialState);

  // ---------------------------------------------------------------------------
  // methods

  const disconnectUser = () => {
    loginService.logout();
    dispatch({ type: ACTIONS.RESET });
    setUserIsLoggedIn(false);
  };

  const disconnectAll = () => {
    walletDisconnect();
    disconnectUser();
  };

  const handleAccountSignAndLogin = async (address, nonce, canSign = false) => {
    let termsAndConditions;
    let signedMessage;
    try {
      termsAndConditions = await termsAndConditionsService.getMarketplaceTermsAndConditions(address);
      dispatch({ type: ACTIONS.SET_TERMS, payload: termsAndConditions });
      const message = siweService.getLoginMessage(termsAndConditions, nonce, address);
      if (!termsAndConditions.alreadyAcceptedByUser) {
        setConnectorActive(true);
        dispatch({ type: ACTIONS.SET_PANE, payload: PANES.TERMS });
        if (userData?.connector?.id === `web3auth` && !canSign) {
          localStorage.removeItem(authInProgressKey);
          return;
        }
      }
      signedMessage = await signMessageAsync({ message });
    } catch (error) {
      console.error(`Failed to sign message: ${error}`);
      dispatch({ type: ACTIONS.SET_ERROR, payload: `Failed to sign message, please reload the page` });
      return;
    }

    try {
      const user = loginService.getUser();

      if (walletAddressAreEqual(userData.address, user?.walletAddress) && user?.token) {
        localStorage.removeItem(authInProgressKey);
        dispatch({ type: ACTIONS.RESET });
        return;
      }
      const baseMessage = siweService.getLoginBaseMessage(termsAndConditions, address);
      const model = {
        nonce,
        baseMessage,
        signedMessage,
        publicAddress: address,
        preferredCulture: `en-US`
      };
      const authenticationData = await walletAuthentication(model);
      const sessionData = await updateUserSession(authenticationData.token, {
        ...termsAndConditions,
        nonce,
        message: baseMessage,
        signedMessage
      });
      loginService.walletLoginCallback(authenticationData, sessionData, `en-US`);
      setUserData((prev) => ({ ...prev, user: loginService.getUser() }));
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      dispatch({ type: ACTIONS.SET_PANE, payload: PANES.CONNECT });
      setConnectorActive(false);
      setReloadProfileData(!reloadProfileData);
    } catch (error) {
      console.error(`Authentication failed: ${error}`);
      dispatch({ type: ACTIONS.SET_ERROR, payload: `Authentication failed, please reload the page` });
    } finally {
      localStorage.removeItem(authInProgressKey);
    }
  };

  const handleAccountConnection = async (address, isAccountSwitch = false, canSign = false) => {
    if (isAccountSwitch && userData?.connector?.id !== `web3auth` && (localStorage.getItem(authInProgressKey) || !address || state.isLoading)) {
      return;
    }

    let nonce;
    try {
      if (userData?.connector?.id !== `web3auth`) {
        localStorage.setItem(authInProgressKey, `true`);
      }
      dispatch({ type: ACTIONS.SET_LOADING, payload: true });
      nonce = await getNonceForOnboardedUser(address, `en-US`);
      dispatch({ type: ACTIONS.SET_NONCE, payload: nonce });
    } catch (error) {
      console.error(`Failed to fetch nonce: ${error}`);
      dispatch({ type: ACTIONS.SET_ERROR, payload: `Failed to fetch nonce, please reload the page.` });
      return;
    }

    await handleAccountSignAndLogin(address, nonce, canSign);
  };

  const doConnect = async (currentConnector) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });

    let address;
    try {
      const {
        accounts: [currentAddress]
      } = await connectAsync({
        connector: currentConnector,
        chainId: TARGET_CHAIN.id
      });
      address = currentAddress;
    } catch (e) {
      console.error(e);
      disconnectAll();
      dispatch({ type: ACTIONS.RESET });
      return;
    }

    await handleAccountConnection(address);
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    setConnectorActive(false);
    dispatch({ type: ACTIONS.RESET });
  }, [escKeyPressed]);

  useEffect(() => {
    if (state.error) {
      disconnectAll();
      localStorage.removeItem(authInProgressKey);
    }
  }, [state.error]);

  useEffect(() => {
    if (!userData?.address || (walletAddressAreEqual(userData.address, userData?.user?.walletAddress) && userData?.user?.token)) return;
    handleAccountConnection(userData.address, true).catch((e) => console.error(e));
  }, [userData?.address]);

  // ---------------------------------------------------------------------------
  // render

  let activePane;
  switch (state.pane) {
    case PANES.CONNECT:
      activePane = `connect`;
      break;
    case PANES.PROS_AND_CONS:
      activePane = `prosAndCons`;
      break;
    case PANES.TERMS:
      activePane = `terms`;
      break;
    default:
      activePane = `manage`;
  }

  return (
    <Container active={connectorActive} isLoading={state.isLoading}>
      <Background />

      {activePane !== `terms` && (
        <Connectors prosAndCons={state.pane === PANES.PROS_AND_CONS}>
          <LoadingAnimation active={state.isLoading || state.error}>
            {state.isLoading && (
              <Image
                css={css`
                  width: 50%;
                `}
                image={loader}
              />
            )}
            {!state.isLoading && state.error && <div style={{ color: `black` }}>An error occurred please try again later.</div>}
          </LoadingAnimation>

          <ConnectorsHeader position={state.pane === PANES.PROS_AND_CONS ? `left` : `right`}>
            <CloseButton
              type="button"
              onClick={() => {
                if (state.pane === PANES.PROS_AND_CONS) {
                  dispatch({ type: ACTIONS.SET_PANE, payload: PANES.CONNECT });
                } else {
                  dispatch({ type: ACTIONS.RESET });
                  setConnectorActive(false);
                  if (state.isLoading || localStorage.getItem(authInProgressKey)) {
                    disconnectAll();
                    localStorage.removeItem(authInProgressKey);
                  }
                }
              }}
            >
              {state.pane === PANES.PROS_AND_CONS ? (
                <Button fluid variant="text" iconLeft="arrowLeft">
                  <span className="caption">Back to login</span>
                </Button>
              ) : (
                <>
                  <CloseButtonInner>
                    <SVG
                      svg="cross"
                      css={css`
                        width: 16px;
                      `}
                      fill={state.isLoading || state.error ? `black` : `white`}
                    />
                  </CloseButtonInner>
                  <span className="visually-hidden">Close</span>
                </>
              )}
            </CloseButton>
          </ConnectorsHeader>

          {activePane === `manage` ? (
            <>
              <ConnectorsHeading className="h3">Manage Wallet</ConnectorsHeading>

              {userData?.address && (
                <>
                  <p
                    css={css`
                      margin-top: 1rem;
                      color: var(--color-mono-40);
                    `}
                    className="b2"
                  >
                    Wallet address:
                  </p>
                  <p
                    css={css`
                      color: var(--color-white);
                    `}
                    className="b2"
                  >
                    {userData.address}
                  </p>
                </>
              )}

              <Button
                css={css`
                  margin-top: 1.5rem;
                `}
                color="primary-orange-100"
                onClick={() => disconnectAll()}
                full
                large
                title="Sign Out"
              >
                <span className="button-text">Sign Out</span>
              </Button>
            </>
          ) : activePane === `prosAndCons` ? (
            <ProsAndCons>
              <ProsAndConsHeading>Pros of logging in with your digital wallet:</ProsAndConsHeading>
              <ProsAndConsList>
                <ProsAndConsListElement>
                  More secure: Your digital wallet is typically more secure than your social media account, as it uses stronger encryption and authentication
                  methods.
                </ProsAndConsListElement>
                <ProsAndConsListElement>
                  More private: Your digital wallet data is not shared with the website or app you are logging into, unlike with social login.
                </ProsAndConsListElement>
                <ProsAndConsListElement>
                  More convenient: You can log in to websites and apps with just a few taps on your phone, without having to remember multiple usernames and
                  passwords.
                </ProsAndConsListElement>
              </ProsAndConsList>

              <ProsAndConsHeading>Cons of logging in with your digital wallet:</ProsAndConsHeading>
              <ProsAndConsList>
                <ProsAndConsListElement>Not as widely supported: Not all websites and apps support login with digital wallets.</ProsAndConsListElement>
                <ProsAndConsListElement>
                  Can be more difficult to set up: You may need to create a new account with your digital wallet provider.
                </ProsAndConsListElement>
              </ProsAndConsList>

              <ProsAndConsHeading>Pros of logging in with social login:</ProsAndConsHeading>
              <ProsAndConsList>
                <ProsAndConsListElement>Widely supported: Most websites and apps support login with social media accounts.</ProsAndConsListElement>
                <ProsAndConsListElement>Easy to set up: You can log in with your existing social media account.</ProsAndConsListElement>
              </ProsAndConsList>

              <ProsAndConsHeading>Cons of logging in with social login:</ProsAndConsHeading>
              <ProsAndConsList>
                <ProsAndConsListElement>
                  Less secure: Your social media account may not be as secure as your digital wallet, and your data could be more easily compromised.
                </ProsAndConsListElement>
                <ProsAndConsListElement>
                  Less private: Your social media data may be shared with the website or app you are logging into.
                </ProsAndConsListElement>
              </ProsAndConsList>
            </ProsAndCons>
          ) : (
            <>
              <ConnectorsHeading className="h3">Login</ConnectorsHeading>

              <p className="b2 connectOverlaySubheading">Connect to Altr with one of these wallets</p>

              <ConnectorList>
                <ConnectOverlayWalletButton key={connectors[0].id} connector={connectors[0]} image={metamask} onClick={() => doConnect(connectors[0])} />
                <ConnectOverlayWalletButton key={connectors[1].id} connector={connectors[1]} image={walletconnect} onClick={() => doConnect(connectors[1])} />
                <SocialLoginConnectors>
                  <p className="b2 connectOverlaySubheading">Or by using your social account</p>
                  <Button className="socialLoginButton" fluid style={{ gridColumn: `1 / 4` }} onClick={() => doConnect(connectors[2])}>
                    <Image image={google} />
                    <span className="socialLoginButtonText">Continue with Google</span>
                  </Button>
                  <Button className="socialLoginButton" fluid style={{ gridColumn: `1 / 2` }} onClick={() => doConnect(connectors[3])}>
                    <Image image={apple} />
                    <span className="visually-hidden">Continue with AppleID</span>
                  </Button>
                  <Button className="socialLoginButton" fluid style={{ gridColumn: `2 / 3` }} onClick={() => doConnect(connectors[4])}>
                    <Image image={x} />
                    <span className="visually-hidden">Continue with X</span>
                  </Button>
                  <Button className="socialLoginButton" fluid style={{ gridColumn: `3 / 4` }} onClick={() => doConnect(connectors[5])}>
                    <Image image={discord} />
                    <span className="visually-hidden">Continue with Discord</span>
                  </Button>
                </SocialLoginConnectors>
              </ConnectorList>

              <ProsAndConsButtonContainer>
                <Button className="underline caption" variant="text" onClick={() => dispatch({ type: ACTIONS.SET_PANE, payload: PANES.PROS_AND_CONS })}>
                  Pros & Cons of every login option
                </Button>
              </ProsAndConsButtonContainer>
            </>
          )}
        </Connectors>
      )}

      {activePane === `terms` && (
        <Terms>
          <ConnectorsHeader>
            <CloseButton
              type="button"
              onClick={() => {
                disconnectAll();
                setConnectorActive(false);
                dispatch({ type: ACTIONS.RESET });
                localStorage.removeItem(authInProgressKey);
              }}
            >
              <CloseButtonInner>
                <SVG
                  svg="cross"
                  css={css`
                    width: 16px;
                  `}
                  fill="black"
                />
              </CloseButtonInner>
            </CloseButton>
          </ConnectorsHeader>

          <header>
            <ConnectorsHeading className="h3">Terms and Conditions</ConnectorsHeading>

            <p
              css={css`
                color: var(--color-mono-40);
              `}
              className="b2"
            >
              Agreement is necessary to proceed.
            </p>
          </header>

          <TermsText>
            <div
              className="caption"
              css={css`
                p {
                  margin-bottom: 1rem;
                }
              `}
              dangerouslySetInnerHTML={{
                __html: state.terms?.content?.values?.[`en-US`]
              }}
            />
          </TermsText>

          {connector?.id !== `web3auth` ? (
            <p
              css={css`
                position: relative;
                display: block;
                margin-top: 1.5rem;
                text-align: center;
              `}
              className="b1"
            >
              Accept the terms with your wallet.
            </p>
          ) : (
            <Button
              css={css`
                position: relative;
                display: block;
                margin-top: 1.5rem;
                text-align: center;
              `}
              className="connectOverlayAcceptButton"
              variant="primary"
              colorTheme="dark"
              fluid
              disabled={state.isLoading}
              onClick={() => {
                if (!userData?.address) return;
                if (!state.nonce) {
                  handleAccountConnection(userData.address, false, true);
                } else {
                  handleAccountSignAndLogin(userData.address, state.nonce, true);
                }
              }}
            >
              <span className="connectOverlayAcceptButtonText">Accept the terms</span>
            </Button>
          )}
        </Terms>
      )}
    </Container>
  );
};

export default ConnectOverlay;
